import React from "react"
import flatMap from "lodash/flatMap"
import { client } from "../src/graphql/client"
import { getLang } from "../src/helpers/getHreflang"

const cardTittleMap = Object.freeze({
  blogPost: "blogPost",
  cs: "caseStudies",
  event: "events",
  resource: "resources",
})

const normalizeCardsData = data => {
  return Object.keys(data).reduce((acc, key) => {
    const typeKey = Object.keys(cardTittleMap).find(k => cardTittleMap[k] === key) || key

    acc[key] = data[key].map(item => ({
      title: item.tabs.content.title,
      description:
        item.tabs?.resourceListingPreview?.excerpt ||
        item.tabs?.csListingPreview?.excerpt ||
        item.tabs?.content.excerpt ||
        "",
      logo:
        item.tabs?.resourceListingPreview?.logo || item.tabs?.csListingPreview?.logo || item.tabs.content.featuredImage,
      page: {
        tabs: {
          content: {
            category: item.tabs.content.category,
            slug: item.tabs.content.slug,
            market: item.tabs.content.market,
          },
        },
        _type: typeKey,
      },
    }))
    return acc
  }, {})
}

export const useFetchLatestCards = cards => {
  const [cardsData, setCardsData] = React.useState([])
  const lang = getLang()

  React.useEffect(() => {
    if (!cards?.length) return

    const requestedTypes = cards.map(card => card.value)

    const queryParts = []

    if (requestedTypes.includes(cardTittleMap.blogPost)) {
      queryParts.push(`
        "blogPost": *[_type == "blogPost" && tabs.content.market == "${lang}"]
          | order(_createdAt desc)[0...1] {
            tabs {
              content {
                market,
                title,
                excerpt,
                slug { current },
                category-> { slug { current } },
                featuredImage { asset-> { ... } }
              }
            }
          }
      `)
    }

    if (requestedTypes.includes(cardTittleMap.event)) {
      queryParts.push(`
        "events": *[_type == "event" && tabs.content.market == "${lang}"]
          | order(_createdAt desc)[0...1] {
            tabs {
              content {
                market,
                title,
                excerpt,
                slug { current },
                category-> { slug { current } },
                featuredImage { asset-> { ... } }
              }
            }
          }
      `)
    }

    if (requestedTypes.includes(cardTittleMap.resource)) {
      queryParts.push(`
        "resources": *[_type == "resource" && tabs.content.market == "${lang}"]
          | order(_createdAt desc)[0...1] {
            tabs {
              content {
                market,
                title,
                slug { current },
                category-> { slug { current } }
              },
              resourceListingPreview {
                title,
                excerpt,
                logo { asset-> { ... } }
              }
            }
          }
      `)
    }

    if (requestedTypes.includes(cardTittleMap.cs)) {
      queryParts.push(`
        "caseStudies": *[_type == "cs" && tabs.content.market == "${lang}"]
          | order(_createdAt desc)[0...1] {
            tabs {
              content {
                market,
                title,
                slug { current },
                category-> { slug { current } }
              },
              csListingPreview {
                title,
                excerpt,
                logo { asset-> { ... } }
              }
            }
          }
      `)
    }

    if (!queryParts.length) return

    const query = `{ ${queryParts.join(",")} }`

    client
      .fetch(query)
      .then(data => {
        setCardsData(flatMap(normalizeCardsData(data)))
      })
      .catch(err => console.error("Error fetching filtered content:", err))
  }, [])

  return { cardsData }
}
