import React from "react"
import PropTypes from "prop-types"
import Carousel from "react-multi-carousel"
import CircleArrow from "../../../assets/svgs/circle-arrow.svg"
import Image from "../image/image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import * as styles from "../partnersCarousel/partnersCarousel.module.scss"
import * as stylesSlider from "./slider.module.scss"

import "react-multi-carousel/lib/styles.css"

const Plug = () => <div />

const PhotoSlider = ({ autoPlay, autoPlaySpeed, photos, itemsToShow, sliderHeight }) => {
  const { medium } = useBreakpoint()
  const containerHeight = medium ? "280px" : `${sliderHeight}px`

  if (!photos.length) return null

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className={styles.buttonGroup}>
        <CircleArrow className={styles.leftArrow} onClick={previous} />
        <CircleArrow className={styles.rightArrow} onClick={next} />
      </div>
    )
  }

  const settings = {
    className: styles.carousel,
    infinite: true,
    draggable: false,
    ssr: true,
    autoPlay,
    autoPlaySpeed,
    renderButtonGroupOutside: true,
    customLeftArrow: <Plug />,
    customRightArrow: <Plug />,
    customButtonGroup: <ButtonGroup />,
    responsive: {
      desktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: itemsToShow,
        partialVisibilityGutter: 30,
      },
      tablet: {
        breakpoint: { max: 1024, min: 0 },
        items: itemsToShow,
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    },
  }

  const Wrapper = ({ children }) =>
    itemsToShow === 1 ? <>{children}</> : <div className={stylesSlider.imgSliderWrapper}>{children}</div>

  return (
    <div
      className={styles.partnersCarousel}
      style={{
        "--carousel-height": containerHeight,
        "--partners-carousel-height": containerHeight,
        "--button-group-height": containerHeight,
      }}>
      <Carousel {...settings}>
        {photos.map(photo => (
          <Wrapper key={photo._key}>
            {itemsToShow === 1 ? (
              <figure>
                <img className={styles.photoSliderImg} src={photo.asset.url} alt="Carousel image" />
              </figure>
            ) : (
              <Image image={photo.asset} />
            )}
          </Wrapper>
        ))}
      </Carousel>
    </div>
  )
}

PhotoSlider.propTypes = {
  autoPlay: PropTypes.bool,
  autoPlaySpeed: PropTypes.number,
  itemsToShow: PropTypes.number,
  sliderHeight: PropTypes.number,
  photos: PropTypes.arrayOf(PropTypes.any),
}

export default PhotoSlider
