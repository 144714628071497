import React, { useCallback, useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { client } from "../../graphql/client"
import { StaticImage } from "gatsby-plugin-image"
import head from "lodash/head"
import cx from "classnames"

import Image from "../../components/image/image"
import RichText from "../richText/richText"
import FormattedLink from "../../components/formattedLink/formattedLink"

import * as s from "./ProfessionalServices.module.scss"

const getMarketId = path => {
  if (path.includes("/es")) return "es"
  if (path.includes("/au")) return "au"
  if (path.includes("/pt")) return "pt"
  return "en-us"
}

const outsideCurvedItems = [2, 3, 4, 5, 6, 7]

function ProfessionalServices(props) {
  const { imagePosition } = props
  const [data, setData] = useState()
  const { pathname } = useLocation()
  const market = getMarketId(pathname)

  const getProfessionalServicesData = useCallback(async () => {
    client
      ?.fetch(
        ` *[_type == "professionalServices" && _id == "professionalServices_${market}"]{
      ...,
      sections[] {
        ...,
        imageLinks[] {
          ...,
          image {
            ...,
            asset->{
              ...,
            }
          }
        }
      }
    }`
      )
      .then(data => {
        setData(head(data))
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }, [market])

  useEffect(() => {
    getProfessionalServicesData()
  }, [])

  const wrapperClassNames = cx(s["wrapper"], {
    [s["styleRow"]]: imagePosition === "row",
    [s["styleRowReverse"]]: imagePosition === "row-reverse",
  })

  if (!data) return null

  return (
    <div className={wrapperClassNames}>
      <div className={s["left"]}>
        <div className={s["chart__wrapper"]}>
          {data?.sections.map((section, index) => (
            <div className={s["chart__section"]} key={section.title.replace(/\s+/g, "").toLowerCase()}>
              <svg viewBox="0 0 500 500" height="740px" width="740px">
                <path
                  id={`curve${index}`}
                  fill="transparent"
                  d={
                    outsideCurvedItems.includes(index)
                      ? "m 31.233725,31.233725 c 0,0 61.574343,63.73891 168.766275,63.73891 121.84084,0 168.76627,-63.73891 168.76627,-63.73891"
                      : "m 31.233725,97.494818 c 0,0 61.574343,-56.261093 168.766275,-56.261093 121.84084,0 168.76627,56.261093 168.76627,56.261093"
                  }
                />
                <text fill="white" fontSize="18px">
                  <textPath xlinkHref={`#curve${index}`} side="left" startOffset="5">
                    {section.title}
                  </textPath>
                </text>
              </svg>

              {section?.imageLinks.map(item => (
                <FormattedLink
                  className={s["chart__section__item"]}
                  key={item?.image?.alt.replace(/\s+/g, "").toLowerCase()}
                  newTab={item?.type === "external"}
                  url={item?.link}>
                  <Image image={item?.image?.asset} alt={item?.image?.alt} />
                </FormattedLink>
              ))}
            </div>
          ))}
          <div className={s["chart__logoWrapper"]}>
            <StaticImage src="../../../assets/images/logo.png" alt="Logo" placeholder="blurred" />
            <span className={s["chart__logoDescr"]}>{data?.logoDescription}</span>
          </div>
        </div>
      </div>
      <div className={s["right"]}>
        <h2 className="mb-xs mt-s">{data?.title}</h2>
        <RichText blocks={data?.richText} />
      </div>
    </div>
  )
}

export default ProfessionalServices
